import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'load',
  component: () => import('../views/load.vue')
},
{
  path: '/announce',
  name: 'announce',
  component: () => import('@/views/announce')
},
{//首页
  path: '/home',
  name: 'home',
  component: () => import('../views/home.vue')
},
{//产品中心
  path: '/product',
  name: 'product',
  component: () => import('@/views/product')
},
{//产品中心-详情
  path: '/productDetail',
  name: 'productDetail',
  component: () => import('@/views/product/detail')
},
{//百大基地
  path: '/base',
  name: 'base',
  component: () => import('@/views/base')
},
{//百大基地-详情
  path: '/baseDetail',
  name: 'baseDetail',
  component: () => import('@/views/base/detail')
},
{//新闻资讯
  path: '/news',
  name: 'news',
  component: () => import('@/views/news')
},
{//新闻资讯-详情
  path: '/newsDetail',
  name: 'newsDetail',
  component: () => import('@/views/news/detail')
},
{//关于我们
  path: '/about',
  name: 'about',
  component: () => import('@/views/about')
},
{//企业战略
  path: '/strategy',
  name: 'strategy',
  component: () => import('@/views/strategy')
},
{//联系我们
  path: '/contact',
  name: 'contact',
  component: () => import('@/views/contact')
},
{//网站地图
  path: '/WebsiteMap',
  name: 'WebsiteMap',
  component: () => import('@/views/WebsiteMap')
},
{//合作模式
  path: '/cooperate',
  name: 'cooperate',
  component: () => import('@/views/cooperate')
},
{//共享加工厂
  path: '/factory',
  name: 'factory',
  component: () => import('@/views/factory')
},



{ path: '*', redirect: '/home', hidden: true }
]
const router = new VueRouter({
  mode: "history",
  routes
})

export default router